.date-card {
    /* position: absolute;
    top: -80px;
    left: 0px; */

    background: #ffffff;
    font-size: 16px;
    margin: 0px auto;
    text-align: center;
    min-width: 80px;
    max-width: 100px;
    color: #000000;
    /* border-left: 1px solid rgb(238, 240, 242) !important; */
    /* border-top: 1px solid #c3c1bc !important; */
    border-right: 1px solid rgb(238, 240, 242);
    /* border-bottom: 1px solid rgb(238, 240, 242) !important; */
    /* border-radius: 5px; */
    padding: 0px;
}

.jan {
    border-left: 5px solid #fbab7e;
    /* background-color: #fbab7e; */
}

.feb {
    border-left: 5px solid #0093e9;
}

.mar {
    border-left: 5px solid #3cba92;
}

.apr {
    border-left: 5px solid #6a85b6;
}

.may {
    border-left: 5px solid #fa709a;
}

.jun {
    border-left: 5px solid #80d0c7;
}

.jul {
    border-left: 5px solid #ff0844;
}

.aug {
    border-left: 5px solid #ff5858;
}

.sep {
    border-left: 5px solid #0093e9;
}

.oct {
    border-left: 5px solid #2a5298;
}

.nov {
    border-left: 5px solid #6f86d6;
}

.dec {
    border-left: 5px solid #5f0a87;
    /* background-color: #5f0a87; */
}

.date-card p {
    margin: 0px auto;
}

.month {
    border-bottom: 1px solid rgb(238, 240, 242) !important;
    border-radius: 5px 5px 0px 0px;
    /* background-color: #6f86d6; */
    /* background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%); */
    /* color: #c3c1bc; */
}

.day-of-month {
    font-size: 22px;
    font-weight: 600;
}

.day-of-week {
    font-size: 18px;
}