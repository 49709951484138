.grid-calendar-header {
    background-color: white;
}

.calendar-header-staff {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 5px 0px;
    text-align: center;
}

.new-default-scroll-view {
    width: 100%;
    position: absolute;
}

.current-time-indicator {
    position: absolute;
    z-index: 10;
    height: 1px;
    /* width: 100%; */
    background-color: red;
}

.current-time-text {
    position: sticky;
    /* position: -webkit-sticky !important; */
    left: 0;
    padding: 3px;
    font-size: 12px;
    justify-content: center;
}

.new-quater-of-hour {
    margin: 0px auto;
    position: relative;
    cursor: pointer;
}


.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.staff-not-available {
    opacity: 1;

    background-image: linear-gradient(rgba(205, 205, 205, 0.4) 1px,
            transparent 2px),
        linear-gradient(to right,
            rgba(205, 205, 205, 0.4) 1px,
            rgba(205, 205, 205, 0.4) 1px);
    /* background-size: 10px 10px; */
}

/* .adm-notice-bar .adm-notice-bar-content {
    width: 100%;
    text-align: center;
} */

.block-time-notice>.adm-notice-bar-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
}

.block-time-notice{
    animation: fadeIn 0.5s ease-in-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}