body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.grecaptcha-badge {
  visibility: hidden;
}

.layout {
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
}

.top-nav {
  flex: 0;
  position: sticky;
  top: 0;
  left: 0;
  border-bottom: solid 1px var(--adm-color-border);
  background-color: #f5f7fa;
  z-index: 50;
  font-weight: 600;
}

.contain {
  flex: 1;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding: 0px 10px 0px 10px;
  background: white;
}

.bottom-nav {
  flex: 0;
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: #f5f7fa;
  border-top: solid 1px var(--adm-color-border);
  z-index: 50;
}